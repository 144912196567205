
body, html {
    padding: 0;
    margin: 0;
    font-family: $font-family-base;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.button {
    @extend .button-text;
    padding: 0.25rem .75rem;
    border-radius: .25rem;
    background-color: shades("default");
    color: shades("white");
    border: 2px solid transparent;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: $transition-default;

    svg {
        width: 0;
        height: 1.5rem;
        opacity: 0;
        fill: shades("white");
        transition: $transition-default;
    }

    &:hover, &:focus {
        color: shades("white");
        text-decoration: none;
        transform: scale3d(1.025, 1.025, 1.025);
        outline: none;

        svg {
            width: 1.5rem;
            opacity: 1;
            fill: shades("white");
        }
    }

    &:active {
        transform: scale3d(1, 1, 1);
        outline: none;
    }

    &--primary {
        @extend .button;
        background-color: theme-color("primary");
    }

    &--fab {
        @extend .button;
        background-color: theme-color("primary");
        padding: .5rem;
        border-radius: 50%;

        svg {
            width: 2rem;
            height: 2rem;
            opacity: 1;
        }

        &:focus, &:hover {
            opacity: .8;

            svg {
                width: 2rem;
            }
        }
    }

    &--primary-ghost {
        @extend .button;
        background-color: transparent;
        border: solid 2px theme-color("primary");
        color: theme-color("primary");

        svg {
            fill: theme-color("primary");
        }

        &:hover, &:focus {
            background-color: theme-color("primary");

            svg {
                fill: shades("white");
            }
        }
    }

    &--secondary {
        @extend .button;
        background-color: theme-color("secondary");
    }

    &--black {
        @extend .button;
        background: shades("black");
    }

    &--white {
        @extend .button;
        background: shades("white");
        color: theme-color("primary");

        svg {
            fill: theme-color("primary");
        }
    }

    &--white-ghost {
        @extend .button;
        background-color: transparent;
        border: solid 2px shades("white");
        color: shades("white");

        svg {
            fill: shades("white");
        }

        &:hover, &:focus {
            background-color: shades("white");
            color: theme-color("primary")
        }
    }

    &:disabled {
        pointer-events: none;
        background: shades("light");
        color: shades("default");
        cursor: not-allowed;

        svg {
            fill: shades("default");
        }

        &:hover, &:focus {
            opacity: 1;
            color: shades("default");
        }
    }
}
p {
    margin: 0;
    line-height: 1.5;
    margin-bottom: 1.5rem;
}

small {
    font-size: .75rem;
    line-height: 1rem;
}


.smaller {
    font-size: .625rem;
    line-height: 1rem;
}

.intro {
    font-size: 1.25rem;
    line-height: 1.2;
}

strong {
    font-weight: 700;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6  {
    line-height: 1.2;
    margin: 0;
    margin-bottom: .5rem;
    font-weight: 700;
    font-family: $font-family-headings;
}

h1, .h1 {
    font-size: 1.75rem;

    @include media-breakpoint-up(md) {
        font-size: 2rem;
    }
}

h2, .h2 {
    font-size: 1.5rem;

    @include media-breakpoint-up(md) {
        font-size: 1.75rem;
    }
}

h3, .h3 {
    font-size: 1.25rem;

    @include media-breakpoint-up(md) {
        font-size: 1.5rem;
    }
}

h4, .h4 {
    font-size: 1.125rem;
}

h5, .h5 {
    font-size: 1rem;
}

h6, .h6 {
    font-size: .875rem;
}

.button-text {
    font-family: $font-family-base;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: .05em;
    font-weight: 700;
    text-transform: uppercase;


    &--small {
        @extend .button-text;
        font-size: .875rem;
    }
}
