﻿.nav {
    width: 100%;
    background: theme-color("primary");
    background: #f2f4f6;
    border-top: 1px solid #dce1e6;
    color: theme-color("primary");

    i {
        color: theme-color("primary");
    }

    padding-bottom: constant(safe-area-inset-bottom, .5rem);
    padding-bottom: env(safe-area-inset-bottom, .5rem);

    .list {
        height: 4rem;
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        width: 100%;


        .item {
            outline: 0;
            cursor: pointer;
            flex: 1 1 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            opacity: .6;
            transition: $transition-default;

            .text {
                height: 0;
                overflow: hidden;
                transition: height .5s;
            }

            &.active {
                opacity: 1;

                .text {
                    height: 15px;
                }
            }

            &:hover, &:focus {
                opacity: 1;
            }


            .link {
                position: relative;
                @extend small;
                text-align: center;
                color: #000;
                text-decoration: none;
                transition: $transition-default;

                &:hover, &:focus {
                    text-decoration: none;
                }

                svg {
                    fill: shades("white");
                    height: 1.75rem;
                    width: 1.75rem;
                    margin-bottom: 0;
                }


                .text {
                    display: block;
                    margin-top: .25rem;
                    font-weight: 500;
                    color: #8199ab;
                }

                .badge {
                    position: absolute;
                    top: 8px;
                    left: 2px;
                    right: 0;
                    font-size: 0.75rem;
                    color: white;
                    padding: 0;
                    width: 15px;
                    text-align: center;
                    line-height: 0;
                }
            }
        }
    }
}
