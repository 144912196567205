﻿.padding-section {
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include media-breakpoint-up(md) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    @media(max-width: 567px) {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    &--top {
        padding-top: 1rem;

        @include media-breakpoint-up(md) {
            padding-top: 2rem;
        }
    }

    &--bottom {
        padding-bottom: 1rem;

        @include media-breakpoint-up(md) {
            padding-bottom: 2rem;
        }
    }
}