﻿.detail {
    @extend .padding-section;

    &__block {
        background: rgba(shades("white"), .8);
        padding: 1rem;
        position: relative;

        @media (min-width: 370px) {
            padding: 1.25rem;
        }

        @include media-breakpoint-up(sm) {
            padding: 1.5rem;
        }
    }

    &__image {
        padding-top: 56.25%;
    }

    &__title {
        @extend .h2;
        margin-bottom: .25rem;
    }

    &__details {
        color: theme-color("primary");
        font-size: .75rem;
        margin-bottom: 1.5rem;
        display: flex;
        flex-flow: row wrap;
    }

    &__date {
        &::after {
            content: "|";
            margin: 0 .25rem;
        }
    }
}