﻿.margin-section {
    margin-top: 3rem;
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }

    &--top {
        margin-top: 3rem;

        @include media-breakpoint-up(md) {
            margin-top: 4rem;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 5rem;
        }
    }

    &--bottom {
        margin-bottom: 3rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 4rem;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 5rem;
        }
    }
}