@use '@angular/material' as mat;

@mixin tr-style {
    th {
        background-color: theme-color("primary");
        color: #ffffff;
        font-weight: normal;
        border: 1px solid theme-color("primary");
        padding: 0.50rem 0.75rem;
    }

    td {
        &:not(.text-input),
        &:not(.cutter-selection),
        &:not(.number-input),
        &:not(.wiper-selection),
        &:not(.tabletime) {
            padding: 0.50rem 0.5rem;
        }

        &.number-input {
            input {
                text-align: right;
            }
        }

        &.text-input, &.cutter-selection, &.number-input, &.wiper-selection, &.tabletime {
            padding: 0;

            .mat-mdc-select, input {
                padding: 0.5rem 5px;
                margin: 0px !important;
                border: none;
            }

            > div {
                display: flex;

                > div {
                    display: flex;
                    align-items: center;

                    &:last-child {
                        padding-right: 5px;
                    }

                    &:first-child {
                        padding-left: 5px;
                    }
                }
            }
        }

        color: theme-color("primary");
        border-right: 1px solid theme-color("primary");

        &:first-child {
            border-left: 1px solid theme-color("primary");
        }

        border-bottom: 1px solid theme-color("primary");
    }
    td.drag {
        position: relative;

        .drag-handle {
            position: absolute;
            top: 0;
            left: -24px;
            padding: 9px 30px 9px 0;
            opacity: 0;
            transition: all 250ms;
            cursor: move;
        }
    }

    &:hover {
        td.drag {
            .drag-handle {
                opacity: 1;
            }
        }
    }
}

tr.cdk-drag-preview {
    @include tr-style;
    box-sizing: border-box;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);

    td {
        opacity: 0.5;
        border: 1px solid transparent;
        display: table-cell;
        color: transparent;

        &.drag {
            .drag-handle {
                opacity: 1 !important;
                color: theme-color("primary");
            }
        }
    }

    display: table-row;
}

tr.cdk-drag-placeholder {
    opacity: 1;

    td {
        background-color: theme-color("secondary")
    }
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}


.table {
    tr {
        @include tr-style;

    }

    &.table-hover {
        tr:hover {
            background-color: transparent;

            td {
                background-color: theme-color("secondary")
            }
        }
    }

    .mat-mdc-icon-button {
        margin: -8px -3px -8px -12px;
    }

    &.table-add-row {
        tr {
            &:last-child {
                td {
                    padding: 0.10rem 0;
                    color: darken(theme-color("secondary"), 8);
                    border-right: 1px solid theme-color("secondary");
                    border-bottom: 1px solid theme-color("secondary");

                    &:first-child {
                        border-left: 1px solid theme-color("secondary");
                    }
                }
            }
        }
    }


    .form-submitted {
        &.ng-invalid {
            td {
                background-color: mat.get-color-from-palette(mat.$red-palette, default, 0.4);
            }
        }
    }

    &.table-last-col {
        tr {

            &:hover {
                &:last-child {
                    tr {
                        background-color: transparent;
                    }
                }
            }

            td, th {
                &:last-child {
                    width: 50px;

                    border: 1px solid transparent;
                    background-color: transparent;
                }
            }

            &.ng-invalid {
                td {
                    &:last-child {
                        background-color: transparent;
                    }
                }
            }
        }

        &.table-add-row {
            tr {
                &:last-child {
                    td {
                        &:last-child {
                            border: 1px solid transparent;
                        }
                    }
                }
            }
        }
    }

    &.table-center {
        th, td {
            text-align: center;
        }
    }

    input, .tabletime, .mat-mdc-select {
        &.ng-touched {
            &.ng-invalid {
                background-color: mat.get-color-from-palette(mat.$red-palette, default, 0.4);
                color: #fff;
            }
        }
    }

    td:has(input.ng-invalid) {
        background-color: mat.get-color-from-palette(mat.$red-palette, default, 0.4);
    }


    .number-input {
        width: 90px;

        input {
            width: 100%;
        }
    }

    .comment {
        width: 390px;
    }

    .datetime {
        width: 240px;
    }

    .tabledatepicker {
        > div {
            display: flex;
        }
    }

    .tabletime {
        width: 122px;

        &.one {
            width: 61px;
        }

        .timepicker {
            display: flex;

            > span {
                line-height: 38px;
            }

            > .mat-mdc-form-field-error {
                display: none;
            }

            &.ng-invalid {
                > .mat-mdc-form-field-error {
                    display: block;
                }
            }

            > .mat-mdc-select {
                padding-bottom: 9px;
                .mat-select-trigger {
                    display: flex;
                    flex-direction: column;
                    height: 25px;
                    margin-bottom: -2px;
                    .mat-select-value {
                        display: block;
                        max-width: 100px;
                        height: 21px;
                        max-height: 23px;
                    }

                    .mat-select-arrow-wrapper {
                        display: flex;
                        justify-content: center;
                        opacity: 0;
                        transition: opacity 200ms;
                    }
                }

                &:focus {
                    .mat-select-trigger {
                        .mat-select-arrow-wrapper {
                            opacity: 1;
                        }
                    }
                }
            }

            &:hover {
                > .mat-mdc-select {
                    .mat-select-trigger {
                        .mat-select-arrow-wrapper {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
