﻿.content {
    font-size: .875rem;

    h2 {
        @extend .h3;
    }

    h3 {
        @extend .h4;
    }

    h4 {
        @extend .h5;
    }

    ul {
        margin: 1.25rem 0;
        padding: 0;
        list-style: none;

        > li {
            position: relative;
            padding-left: 1.25rem;
            margin: .25rem 0;
            line-height: 1.5;

            &::before {
                content: "";
                background: theme-color("primary");
                width: 0.375rem;
                height: 0.375rem;
                border-radius: 50%;
                position: absolute;
                transform: translateY(-50%);
                left: 0;
                top: .75rem;
            }
        }

        ul, ol {
            margin: 0;
        }
    }

    ol {
        margin: 1.25rem 0;
        padding: 0;
        list-style: none;
        counter-reset: inst;

        > li {
            counter-increment: inst;
            position: relative;
            padding-left: 1.25rem;
            line-height: 1.5;
            margin: .25rem 0;

            &::before {
                @extend .subtitle--small;
                line-height: 1.5rem;
                content: counter(inst);
                display: inline-block;
                position: absolute;
                width: 1.25rem;
                left: 0;
                color: theme-color("primary");
            }
        }

        ul, ol {
            margin: 0;
            counter-reset: inst;
        }
    }
}