﻿.topbar {
    user-select: none;
    background: shades("white");

    .fa-info-circle {
        font-size: 1.75rem;
    }

    &__flex {
        padding: 0 1rem;
        display: flex;
        min-width: 0;
        width: 100%;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;

        a.link {
            color: theme-color("primary");
        }

        @media(max-width: 567px) {
            padding: 0 !important;
        }
    }

    .dropdown {
        min-width: 0;
    }

    &__brand {
        margin-right: 1rem;

        svg {
            height: 2rem;
            width: 2.608rem;

            @media (min-width: 360px) {
                height: 2.25rem;
                width: 2.934rem;
            }

            @include media-breakpoint-up(md) {
                height: 2.5rem;
                width: 3.26rem;
            }
        }
    }

    &__button {
        @extend .button--primary;
        border: 2px solid theme-color("secondary");
        background: #FFF;
        color: theme-color("secondary");
        min-width: 0;
        display: flex;
        padding: .15rem .75rem;
        font-size: .8rem;
        font-weight: normal;
        text-transform: none;
        max-width: calc(100vw - 130px);

        &:hover, &:focus {
            color: theme-color("secondary");
        }

        i {
            opacity: 1;
            color: theme-color("secondary");
            margin-top: 5px;
            margin-left: 10px;
        }

        span {
            @extend .overflow-ellipsis;
        }
    }

    &__link {
        min-width: 0;
        display: flex;
        padding: .25rem .75rem;
        align-items: center;

        svg {
            width: 1.5rem;
            opacity: 1;
        }

        span {
            @extend .overflow-ellipsis;
        }
    }

    .dropdown-menu {
        position: absolute;
    }

    @media(max-width: 567px) {
        padding: .5rem;
        padding-top: calc(.5rem + constant(safe-area-inset-top, .5rem));
        padding-top: calc(.5rem + env(safe-area-inset-top, .5rem));
    }
}
