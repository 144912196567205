﻿.subtitle {
    @extend .h4;
    text-transform: uppercase;
    letter-spacing: .05em;
    font-weight: 400;

    &--small {
        font-size: .75rem;
        font-weight: 700;
    }
}