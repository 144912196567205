// Font
$font-family-headings: 'Asap', sans-serif;
$font-family-base: 'Open Sans', sans-serif;

// Borders
$border-radius: .5rem;

// Shadows
$shadow-text: .125rem .125rem .25rem rgba(0, 0, 0, .1), .25rem .25rem .5rem rgba(0, 0, 0, .1), .5rem .5rem 1rem rgba(0, 0, 0, .1);
$shadow-hover: .25rem .25rem .5rem rgba(0, 0, 0, .05), .5rem .5rem 1rem rgba(0, 0, 0, .05), 1rem 1rem 2rem rgba(0, 0, 0, .05), 2rem 2rem 4rem rgba(0, 0, 0, .05);

// Transition
$transition-default: .3s;

// Grid
$grid-columns: 24;
$grid-gutter-width: 2rem;

// Colors
$theme-colors: (
    "primary": #d51067,
    "secondary": #8199ab,
);

$shades: (
    "black": #000000,
    "dark": #262d33,
    "default": #8199ab,
    "light": #f2f4f6,
    "white": #ffffff,
);

@function theme-color($key: "primary") {
    @return map-get($theme-colors, $key);
}

@function shades($key: "default") {
    @return map-get($shades, $key);
}

// Gradients
$gradient-black-up: linear-gradient(to top, rgba(shades("black"), 1) 0%, rgba(shades("black"), 0) 100%);


$codalt-green: #2ae7ac;
$codalt-gray-dark: #2f3841;
$codalt-gray-light: #414e5a;
$codalt-blue-bg: #f5f8fa;

