.item-edit {
    .classroom-list {
        display: flex;
        flex-wrap: wrap;

        .mat-mdc-list-item {
            width: 156px;
            height: 36px;
        }

        .mdc-list-item__content {
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .school-list {
        display: flex;
        flex-wrap: wrap;

        .mat-mdc-list-item {
            width: 220px;
            height: 36px;
        }

        .mdc-list-item__content {
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .save-button, .file-button {
        position: fixed;
        right: 15px;
        z-index: 999;
    }

    .save-button {
        bottom: 85px;
    }

    .file-button {
        bottom: 155px;
    }

    .main-image {
        width: 100%;
        height: 300px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        app-codalt-image {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        file-manager {
            position: absolute;
            bottom: 25px;
            left: 25px;
        }

        .info {
            color: #FFFFFF;
            text-shadow: 1px 1px 3px #000000;
            padding: 25px;
        }
    }

    .images {
        display: flex;
        flex-direction: column;
        margin: -5;
        justify-content: center;

        > div {
            padding: 5px;
            position: relative;

            .uploading {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 40px;
                background-color: #000000;
                color: #FFFFFF;
            }

            app-codalt-image, app-video, .uploading {
                width: 300px;
                height: 200px;
                @media(min-width: 768px) {
                    width: 500px;
                    height: 300px;
                }
                transition: opacity 250ms;

                > button {
                    opacity: 0;
                }

                &:hover {
                    > button {
                        opacity: 1;
                    }
                }
            }

            button {
                position: absolute;
                left: 10px;
            }
        }
    }
}

.daterange {
    padding-top: 8px;
    @media(max-width: 767px) {
        &:nth-of-type(odd) {
            background-color: rgba(theme-color("primary"), 0.1);
        }
    }
    @media(min-width: 768px) {
        &:hover {
            background-color: rgba(theme-color("primary"), 0.1);
        }
    }

}
