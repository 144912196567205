.chat {
    &__title {
        text-align: center;

        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }

    &__block {
        background: shades("white");
        margin: 0.5rem 0;
    }

    &__button-wrapper {
        text-align: right;
        margin-top: 2rem;
    }

    &__fab {
        @extend .button--fab;
    }
}

.chat-item {
    display: flex;
    align-items: center;
    position: relative;
    padding: .75rem;
    border-top: solid 1px shades("light");
    text-decoration: none;
    color: shades("black");
    transition: $transition-default;
    background: shades("white");
    $chat-item: &;

    &:hover, &:focus {
        text-decoration: none;
        opacity: .8;
    }

    &__image-width {
        flex: 0 0 20%;
        @media(min-width: 1200px) {
            flex: 0 0 13%;
        }
    }

    &__image-wrapper {
        @extend .circle-wrapper;
    }

    &__image {
        @extend .position-absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        .fas, .far, .fa-duotone {
            font-size: 30px;
        }
        .fa-volleyball{
            font-size: 50px;
            color: theme-color("primary");
        }
    }

    &__wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;
        flex: 0 0 80%;
        padding-left: .75rem;
        min-width: 0
    }

    &__title {
        @extend .h5;
        @extend .overflow-ellipsis;
        flex: 1 1 100%;
        margin-bottom: .25rem;
    }

    &__subtitle {
        @extend .overflow-ellipsis;
        font-size: .875rem;
        font-family: $font-family-headings;
        font-weight: 400;
        font-style: italic;
        margin-top: -.25rem;
        margin-bottom: .25rem;
    }

    &__content-wrapper {
        flex: 0 0 100%;
        display: flex;
        align-items: flex-end;
        min-width: 0
    }

    &__content {
        flex: 1 1;
        min-width: 0
    }

    &__message {
        @extend small;
        @extend .overflow-line-clamp;
        flex: 1;
        margin-bottom: 0;
        color: shades("default");
    }

    &__time {
        @extend .smaller;
        flex: 0 0 auto;
        padding-left: .125rem;
        color: shades("default");
    }

    &--group {
        @extend .chat-item;

        #{$chat-item}__message {
            -webkit-line-clamp: 2;
        }
    }
}
