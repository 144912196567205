@import "../../../../node_modules/bootstrap/scss/mixins/grid";

.items {
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
        margin-top: 17px;

        .mdc-text-field--filled .mdc-line-ripple::before {
            display: none;
        }

        .mat-mdc-text-field-wrapper {
            border-radius: 4px;
        }
        mat-form-field{
            min-width: 225px;
        }

    }

    .item-list {
        height: calc(100vh - 136px);
        flex: 1 1;

        .scrollable-content {
            height: calc(100% - 140px);
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            position: relative;
            margin: 70px auto;
            @include make-container();
            @include make-container-max-widths();
            @media(max-width: 567px) {
                padding-left: .5rem;
                padding-right: .5rem;
            }

            .title {
                position: absolute;
                top: -68px;
                padding: 0 1rem;
                left: 0;
                right: 0;
                display: flex;

                .mat-mdc-form-field {
                    margin-top: -11px;
                }

                @media(max-width: 567px) {
                    padding-left: .5rem;
                    padding-right: .5rem;
                }
            }

            .item {
                width: calc(33.3333333% - .34rem);
                min-width: 250px;
                background: none;
                cursor: pointer;
                margin-bottom: .5rem;
                position: relative;

                &:nth-child(3n+1), &:nth-child(3n+2) {
                    margin-right: .5rem;
                }

                @media(max-width: 992px) {
                    width: calc(50% - .33rem);
                    &:nth-child(2n+0) {
                        margin-right: 0;
                    }
                    &:nth-child(2n+1) {
                        margin-right: .5rem;
                    }
                }

                @media(max-width: 767px) {
                    width: 100%;
                    margin-right: 0 !important;
                }

                .detail__block {
                    height: 110px;
                    overflow: hidden;
                    justify-content: center;
                    padding-top: 0;
                    padding-bottom: 0;
                    display: flex;
                    flex-direction: column;
                    position: relative;

                    h3 {
                        @extend h5;
                        max-height: 57px;
                        overflow: hidden;
                        position: relative;

                        &.unread {
                            padding-left: 10px;
                            color: theme-color();

                            &:before {
                                z-index: 5;
                                content: ' ';
                                display: block;
                                background-color: theme-color();
                                width: 6px;
                                height: 6px;
                                border-radius: 3px;
                                position: absolute;
                                top: 8px;
                                left: 0;
                            }
                        }
                    }

                    &.alert {
                        padding-left: 3rem;
                        color: theme-color("primary");
                        background: #FFFFFF;

                        &:before {
                            position: absolute;
                            top: 0;
                            left: 0;
                            content: ' ';
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-image: url('../../../assets/svg/alert.svg');
                            width: 3rem;
                            height: 100%;
                            background-position: center;
                            fill: theme-color("primary");
                        }
                    }

                    &.employee {
                        padding-left: 3rem;
                        background: #FFFFFF;

                        &:after {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            content: ' ';
                            background-size: 1.25rem auto;
                            background-repeat: no-repeat;
                            background-image: url('../../../assets/svg/chalkboard-teacher-solid.svg');
                            width: 3rem;
                            height: 100%;
                            background-position: center;
                        }
                    }

                    &.offer {
                        padding-left: 3rem;
                        background: #FFFFFF;

                        &:after {
                            color: theme-color("primary");
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            content: '\f45f';
                            font-family: 'Font Awesome 6 Pro';
                            width: 3rem;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                    &.employee.alert {
                        &:before, &:after {
                            height: 50%;
                        }
                    }
                }

                .details {
                    color: theme-color("primary");
                    font-size: .75rem;
                    margin-bottom: 0;
                    display: flex;
                    justify-content: space-between;
                    flex-flow: row wrap;

                    h3 {
                        text-align: justify;
                    }

                    .detail__block {
                        padding: 1.25rem;
                    }
                }
            }
        }
    }
}

.item-images {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;


    @media(max-width: 767px) {
        margin: 0 -.75rem;
    }

    > app-codalt-image, > app-video > app-codalt-image, app-video, .uploading {
        max-height: 210px;
        cursor: pointer;
        margin-bottom: .5rem;
        width: calc(50% - .33rem);

        &:nth-child(2n+0) {
            margin-right: 0;
        }

        &:nth-child(2n+1) {
            margin-right: .5rem;
        }

        &:before {
            padding-top: 65%;
            content: ' ';
            display: block;
        }

        @media(max-width: 767px) {
            width: 100%;
            margin-right: 0 !important;
        }
    }
}
