﻿.dropdown {
    position: relative;

    .dropdown-menu {
        right: 0;
        left: auto;
        margin-top: .75rem;
        opacity: 0;
        pointer-events: none;
        background: shades("white");
        overflow: auto;
        z-index: 1000;
        max-height: calc(100vh - 131px);
        margin-left: -.5rem;
        text-align: left;
        box-shadow: $shadow-hover;
        border-radius: $border-radius;
        transition: $transition-default;
        transform: translateY(3rem);
        min-width: 12rem;

        &__item {
            text-decoration: none;
            transition: $transition-default;
            cursor: pointer;
            display: block;

            &:hover {
                text-decoration: none;
            }
        }

        &__item {
            color: shades("black");
            font-size: .875rem;
            margin: 0 0 -.25rem;
            padding: 1rem 1.5rem;

            &:last-of-type {
                margin-bottom: 0;
            }

            &:hover {
                color: shades("black");
                background: shades("light");
            }
        }
    }

    &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 1;
        background: shades("black");
        opacity: 0;
        pointer-events: none;
        transition: $transition-default;
    }

    // Active state
    &.show {
        &::before {
            opacity: .25;
        }

        .dropdown-menu {
            opacity: 1;
            transform: translateY(0);
            pointer-events: auto;
        }

        .dropdown-toggle {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}
