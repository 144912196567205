.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: .5rem;
}

.login {
    background-color: #FFFFFF;
    color: theme-color("primary");
    max-width: 500px;
    width: 100%;
    padding: 50px;
    border-radius: .25rem;

    .mat-mdc-form-field {
        width: 100%;
    }

    @media(max-width: 767px) {
        padding: 2rem 1rem;
    }
}
