﻿.main {
    background: shades("light");
    position: relative;
    overflow: hidden;

    &__triangle {
        svg {
            position: absolute;
            left: 66.667%;
            top: 33.333%;
            width: 100vw;
            height: 126.087vw;
            transform: translate(-50%, -50%);
            fill: darken(shades("light"), 7.5%)
        }
    }

    .router-outlet {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        height: 100%;
        position: relative;
        margin-top: -4px;

        .container.white {
            background-color: #FFFFFF;
            margin-top: 2rem;
            padding: 25px;
            border-radius: .25rem;
            margin-bottom: 25px;
            @media(max-width: 767px) {
                margin-top: .5rem;
                padding: .5rem;
                max-width: calc(100% - 1rem);
            }
        }
    }
}
