// Global variables
@use '@angular/material' as mat;
@import "variables";

@import '@angular/cdk/overlay-prebuilt.css';
@import './../../../node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss';
@import "./../../../node_modules/swiper/swiper-bundle";

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$black-87-opacity: rgba(black, 0.87);

$mat-kindwijs: (
        50: #FFFFFF,
        100: lighten(theme-color("primary"), 40),
        200: lighten(theme-color("primary"), 30),
        300: lighten(theme-color("primary"), 15),
        400: theme-color("primary"),
        500: theme-color("primary"),
        600: theme-color("secondary"),
        700: theme-color("secondary"),
        800: darken(theme-color("secondary"), 5),
        900: darken(theme-color("secondary"), 10),
        A100: #FFFFFF,
        A200: theme-color("primary"),
        A400: theme-color("primary"),
        A700: theme-color("primary"),
        contrast: (
                50: $black-87-opacity,
                100: $black-87-opacity,
                200: $black-87-opacity,
                300: $black-87-opacity,
                400: $black-87-opacity,
                500: white,
                600: white,
                700: white,
                800: white,
                900: white,
                A100: $black-87-opacity,
                A200: $black-87-opacity,
                A400: $black-87-opacity,
                A700: white,
        )
);


$app-primary: mat.define-palette($mat-kindwijs);
$app-accent: mat.define-palette($mat-kindwijs, A200, A100, A400);
$app-warn: mat.define-palette(mat.$red-palette);

$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

@include mat.all-component-themes($app-theme);

.mat-mdc-dialog-container .mdc-dialog__content {
    color: #000000 !important;
}


@import "external/all-external";
@import "utilities/all-utilities";
@import "app/all-app";

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    .row > .col,
    .row > [class*="col-"],
    .container {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .row {
        margin-left: -.5rem;
        margin-right: -.5rem;
    }
}

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}


.clearfix {
    clear: both;
}

.w100 {
    width: 100%;
}

.curpoint {
    cursor: pointer !important;
}

.curhelp {
    cursor: help;
}

.curmove {
    cursor: move;
}

.txtcenter {
    text-align: center;
}

.modal-backdrop {
    display: none;
}

.images-overview .image {
    background-size: auto 70%;
    background-image: url('../svg/image.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    cursor: pointer;

    &:before {
        content: ' ';
        z-index: -1;
        padding-top: 67%;
        display: block;
    }

    .delete {
        position: absolute;
        top: 15px;
        right: 15px;

        i {
            color: theme-color();
        }
    }
}

a, a:hover {
    color: theme-color("primary");
}


.gu-mirror {
    &.page-container {
        position: relative;
        min-height: 100px !important;

        .content {
            display: none;
        }

        &:before {
            cursor: move;
            content: ' ';
            display: block;
            position: absolute;
            z-index: 9999999;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $codalt-gray-light;
        }

    }
}

.cursor-default {
    cursor: default;
}

.mat-calendar {
    table th, table td {
        border: none;
    }
}

.add-floating-button {
    position: fixed !important;
    bottom: 85px;
    right: 15px;
    bottom: calc(85px + env(safe-area-inset-bottom, 0));
    right: calc(15px + env(safe-area-inset-right, 0));

}

.delete-button {
    position: fixed !important;
    bottom: 160px;
    right: 15px;
    bottom: calc(160px + env(safe-area-inset-bottom, 0));
    right: calc(15px + env(safe-area-inset-right, 0));
    background-color: theme-color("secondary") !important;
}

.copy-button {
    position: fixed !important;
    bottom: 235px;
    right: 15px;
    bottom: calc(235px + env(safe-area-inset-bottom, 0));
    right: calc(15px + env(safe-area-inset-right, 0));
    background-color: theme-color("secondary") !important;
}

.floating-buttons {
    z-index: 1;
    position: fixed !important;
    bottom: 85px;
    right: 15px;
    bottom: calc(85px + env(safe-area-inset-bottom, 0));
    right: calc(15px + env(safe-area-inset-right, 0));
    display: flex;
    flex-direction: column;


    button {
        margin-top: 15px;

    }

    &:not(.leave-color) {
        button {
            &:not(:last-child) {
                &:not(.mat-mdc-menu-trigger) {
                    background-color: theme-color("secondary") !important;
                }
            }
        }

    }

    transition: opacity 350ms;

    &.hide {
        opacity: 0.05;
    }
}

.container.bg-white {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.not-found {
    position: fixed;
    top: 40%;
    text-align: center;
    width: 100%;
    color: theme-color("primary");
    font-size: 1.2em;
}

.image-viewer .mat-mdc-dialog-container {
    background: none;
    padding: 0;

    .mat-mdc-dialog-surface {
        background-color: transparent;
    }
}

.cursor-ns-resize {
    cursor: ns-resize;
}

.list-group-item.active {
    background-color: theme-color();
    border-color: theme-color();
}

.flex-even {
    flex: 1;
}

.chat-dialog {
    .mat-mdc-dialog-container {
        padding: 0;
    }
}

h2 {
    flex: 1 1;
    box-sizing: border-box;
    mat-checkbox {
        float: right;
        font-size: 1rem;
    }
}

.readby {
    font-size: 14px;
}

.discussion-detail .mat-mdc-dialog-container {
    overflow: visible;
}

.dropdown.ngx-contextmenu {
    .dropdown-item.passive {
        &:hover {
            background: none;
        }

        &:active {
            color: #000000;
        }
    }

    .dropdown-item {
        &:active {
            color: #FFFFFF;
            background: theme-color();
        }
    }

    &:before {
        z-index: -1;
    }
}

.mat-mdc-tooltip {
    font-size: .9em;
    white-space: pre-line !important;
}

@media(max-width: 767px) {
    .discussion-detail {
        max-width: 95vw !important;
    }
}

.line-through {
    text-decoration: line-through;
}

.unread {
    &:before {
        z-index: 5;
        content: ' ';
        display: block;
        background-color: theme-color();
        width: 6px;
        height: 6px;
        border-radius: 3px;
        position: absolute;
        top: calc(50% - 3px);
        left: -3px;
        box-shadow: .5px .5px 1px #FFFFFF;
    }
}

.cdk-drag-preview.image-edit {
    app-codalt-image, app-video {
        width: 300px;
        height: 200px;
    }


    button {
        display: none;
    }
}

.image-edit {
    position: relative;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;

    .drag-handle {
        padding: 20px;
        background: rgba(0, 0, 0, 0.5);
        color: #FFFFFF;
        right: 10px;
        position: absolute;
        text-align: center;

        i.fa {
            font-size: 2rem;
        }
    }
}

.image-viewer {
    min-width: 100vw;
}

.item-documents {
    margin-top: 1rem;

    tr {
        cursor: pointer;

        td {
            &:first-of-type {
                color: theme-color("primary");
                padding-left: 0;
            }
        }

        &:hover {
            color: theme-color("primary");
        }
    }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: theme-color('primary');
}

.ng-select.ng-select-focused .ng-select-container:after {
    border-color: theme-color('primary');
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    color: theme-color('primary');
}

.no-outline:focus {
    outline: none;
}

.dropdown::before {
    z-index: 2;
}

.table-fixed {
    table-layout: fixed;
}

button {
    outline: none !important;
}


.transactions {
    background-color: #FFFFFF;

    .transaction {
        display: flex;
        align-items: center;

        &.head {
            padding: 1.5rem 1rem 0.5rem 1rem;

            .action {

            }
        }

        &.saldo {
            border-bottom: 1px solid theme-color("secondary");
            margin-bottom: 0.5rem;
            padding-bottom: 1rem;
            justify-content: space-between;

            .info {
                padding: 0 1rem 1rem 1rem;
                text-align: right;
            }
        }

        .icon {
            padding: 1rem;
            display: flex;
            align-items: center;
        }

        .info {
            flex: 1 1;

            .date {
                font-size: 12px;
            }
        }

        .amount {
            padding: 0 1rem;

            .fas {
                color: theme-color("secondary");
            }

            span {
                background-color: theme-color("secondary");

                &.add {
                    background-color: theme-color("primary");
                }

                color: #FFFFFF;
                border-radius: 0.25rem;
                padding: 0.25rem;
            }
        }

        &:last-child {
            padding-bottom: 0.5rem;
        }
    }
}

.drop-up-menu-trigger {

    .fa-chevron-up {
        transition: all 200ms linear;

    }
}

.drop-up-menu {
    background-color: transparent !important;
    box-shadow: none !important;
    min-width: auto !important;
    overflow: visible !important;


    .mat-mdc-menu-content {
        display: flex;
        flex-direction: column;

        button {
            margin-top: 0.5rem;
            background-color: theme-color("secondary") !important;
        }

    }
}


button.mat-mdc-fab {
    width: 45px;
    height: 45px;
    .mat-button-wrapper {
        padding: 12px 0;
    }

}


.drop-up-backdrop + .cdk-overlay-connected-position-bounding-box {
    bottom: initial !important;
}

label {
    margin-bottom: 0;
    letter-spacing: normal;
}

.mdc-button, .mat-mdc-dialog-title {
    letter-spacing: normal;
}

mat-radio-button {
    .mdc-label {
        cursor: pointer !important;
    }
}

button {
    mat-icon {
        font-size: 18px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
}

mat-label, input, mat-hint {
    letter-spacing: 0 !important;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0 4px 0 4px !important;

    > mat-hint, > mat-error {
        display: flex;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 1.1;
        font-size: 11px;
        margin-top: 1px;
    }
}

mat-form-field {
    margin-bottom: 6px !important;

    .mat-focused {
        background-color: transparent !important;
    }

    .mat-mdc-text-field-wrapper {
        background-color: shades("light") !important;
    }

    .mat-mdc-form-field-focus-overlay {
        background-color: rgba(255, 255, 255, 0.9) !important;
    }

}

.mat-mdc-subheader {
    font-weight: 500 !important;
    font-size: 0.9rem !important;
    color: shades("dark") !important;
}


.mat-mdc-list-option-checkbox-before {
    margin-right: 4px !important;
}
