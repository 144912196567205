﻿.news-home {
  margin-bottom: 2rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.news-home .item {
  &:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  &:last-child {
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  &__details {
    color: shades("light");
    font-size: .75rem;
    margin-bottom: 0;
    display: flex;
    flex-flow: row wrap;
  }

  &__date {
    &::after {
      content: "|";
      margin: 0 .25rem;
    }
  }
}

.calendar-home, .news-home {
  &__title {
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  .item {
    cursor: pointer;
    display: block;
    position: relative;
    padding: .75rem 1rem;
    text-decoration: none;
    color: shades("white");
    background: shades("default");
    transition: $transition-default;
    $item: &;

    &:hover {
      color: shades("white");
    }

    &:nth-of-type(even) {
      color: shades("white");
      background: lighten(shades("default"), 10%);
    }

    &:hover, &:focus {
      text-decoration: none;
      opacity: .8;
    }

    &__title {
      @extend .h5;
      margin-bottom: .25rem;
    }

    &__date {
      color: shades("light");
      font-size: .75rem;
    }

    &__alert, &__employee {
      i, svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 3rem;
        height: 3rem;

        path {
          fill: theme-color("primary");
        }
      }

      i {
        display: flex;
        align-items: center;
        width: 2rem;
      }
    }

    &__employee {
      svg {
        path {
          fill: #FFFFFF;
        }

        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
      }
    }

    &.alert {
      color: theme-color("primary");
      background: shades("white");
      border: 0;
      border-radius: 0;

      .item__date, .item__author {
        color: theme-color("primary") !important;
        opacity: .75;
      }
    }

    &.alert, &.employee {
      .item__details, .item__title {
        margin-right: 2rem;
      }

      margin-bottom: 0;
    }

    &.alert.employee {
      .item__employee svg {
        top: 30%;

        path {
          fill: theme-color();
        }
      }

      .item__alert svg {
        top: 65%;
      }
    }
  }
}
