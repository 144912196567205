﻿.position-absolute {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

    &--half-bottom {
        @extend .position-absolute;
        top: 50%;
        height: 50%;
    }
}
